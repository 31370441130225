import {Fragment, useState} from 'react';
import Header from '../components/Header';
import {Link, useHistory, useParams} from 'react-router-dom';
import Button from '../components/Button';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import useSWR from 'swr';
import NotFound from '../pages/Notfound';

export default function DiscordLinkConfirm() {
  const [loading, setLoading]=useState(false);
  const history = useHistory();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/auth/discord/pending`, fetcher);

  if (!data) return <></>;

  if (data.status != 200) return <NotFound/>;
  const user = data.data;

  function complete() {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/auth/discord/confirm`, {}, {withCredentials: true}).then((r) => {
      window.location.href = '/settings/linked';
    });
  }

  return (<RequireUser>
    <Header/>
    <div className="main-content right-chat-active position-relative">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row justify-content-center">
            <div className="col-xl-12 h-no-header align-items-center d-flex">

              <div className="card border-0 d-block p-3 mx-auto" style={{width: 'fit-content', maxWidth: 650}}>
                <h2 className="fw-700 text-grey-900 display1-size">You are about to link <b>{user.username}</b> to your onrain account.</h2>
                <p className="text-grey-500 font-xsss">This means that you can log in with this discord account and have access to certain linked roles some guilds may have set up. Are you sure you want to do this?
                This cannot be undone without contacting staff.</p>
                <div className="d-flex flex-row align-content-end">
                  <Button className="btn-success p-3 ms-auto" color="green" onClick={complete} loading={loading}>Confirm</Button>
                </div>
                {/* <Link to={link}*/}
                {/*  className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">{linkText}</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </RequireUser>);
}
