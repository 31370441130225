import {Fragment, useEffect, useRef, useState} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import RightMessages from '../components/header/RightMessages';
import CreatableSelect from 'react-select/creatable';
import Button from '../components/Button';
import {ActionMeta, OnChangeValue, StylesConfig} from 'react-select';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Pagetitle from '../components/Pagetitle';
import Load from '../components/Load';
import {MessageGroupPreview, ProfileInfo} from '../api-types';
import {getInitials, getRelativeTime} from '../helpers/utils';
import {BsChevronDown, BsChevronRight} from 'react-icons/bs';
import {Message} from '@prisma/client';

const API_URL = process.env.REACT_APP_API_URL;
type Selection = ({isFixed?: boolean; value: string; label: string});


export default function MessagesList() {
  const {user, reloadUser} = useUser();
  const history = useHistory();
  const [createOpen, setCreateOpen] = useState(false);
  const toggleCreateOpen = () => setCreateOpen(!createOpen);

  const [selected, setSelected] = useState<Selection[]>([]);
  const [entries, setEntries] = useState<MessageGroupPreview[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [creating, setCreating] = useState<boolean>(false);
  const [creatingGroup, setCreatingGroup] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Selection[]>(user?.activeProfile?.profile.following
      .map((obj) => {
        return {value: obj.tag, label: `@${obj.tag}`};
      }));
  const [connect, setConnect] = useState<number>(0);
  const ws = useRef<WebSocket>(null);
  useEffect(() => {
    console.log('Attempting to connect to websocket server');
    ws.current = new WebSocket(process.env.REACT_APP_WS_URL);
    ws.current.onopen = () => console.log('Connection to websocket server established');
    ws.current.onclose = () => {
      console.log('Connection to websocket server lost...');
      setTimeout(() => {
        setConnect(connect + 1);
      }, 5000);
    };

    ws.current.onmessage = (e) => {
      const msg = JSON.parse(e.data);
      switch (msg.type) {
        case 'NEW_MESSAGE': {
          const message: Message & ProfileInfo = msg.data.message;
          const muted: boolean = msg.data.muted;
          setEntries((state) => {
            if (state.some((g) => g.id === message.groupId)) {
              const current = state.find((g) => g.id === message.groupId);
              state.splice(state.indexOf(current), 1);
              return [{...current, lastMessage: message, unread: muted ? 0 : current.unread+1}, ...state];
            } else {
              axios.get(`${process.env.REACT_APP_API_URL}/messages/latest/groups?count=10`, {withCredentials: true})
                  .then((res) => {
                    const data: MessageGroupPreview[] = res.data;
                    const q = data.find((g) => g.id === message.groupId);
                    if (q !== undefined) {
                      setEntries((state) => [q, ...state]);
                    }
                  });
              return state;
            }
          });
        }
      }
    };

    const client = ws.current;
    return () => {
      client.close();
      ws.current = null;
    };
  }, [connect]);

  if (!user) {
    return <RequireUser> </RequireUser>;
  }

  const more = () => {
    if (loading || !hasMore) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/messages/latest/groups?count=25${entries.length > 0 ? `&last_groupid=${entries[entries.length-1].id}&last_messageid=${entries[entries.length-1].lastMessage.id}` : ''}`, {withCredentials: true})
        .then((res) => {
          const data: MessageGroupPreview[] = res.data;
          setEntries(entries.concat(data));
          setHasMore(data.length === 25);
          setLoading(false);
        });
  };

  const onScroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
      more();
    }
  };

  useEffect(() => {
    more();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });


  const onChange = (value: OnChangeValue<Selection, true>,
      actionMeta: ActionMeta<Selection>) =>{
    switch (actionMeta.action) {
      case 'pop-value':
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = selected.filter((v) => v.isFixed);
        break;
    }
    // @ts-ignore
    setSelected(value);
  };

  const onCreateOption = async (inputValue: string) => {
    if (creating || inputValue.toLowerCase() === user?.activeProfile?.profile.tag) return;
    setCreating(true);
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${inputValue}`, {validateStatus: () => true});
    if (data.data.success === true) {
      setSelected(selected.concat({value: inputValue, label: `@${inputValue}`}));
    }
    setCreating(false);
  };

  const createGroup = async () => {
    if (creatingGroup || selected.length < 2) return;
    setCreatingGroup(true);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/messages/group/open`, {type: 'GROUP', profiles: selected.map((selection) => selection.value)}, {validateStatus: () => true, withCredentials: true});
    if (res.status === 200) {
      history.push(`/messages/${res.data.groupId}`);
    } else {
      setCreatingGroup(false);
    }
  };

  const styles: StylesConfig<Selection, true> = {
    multiValue: (base, state) => {
      return state.data.isFixed ? {...base, backgroundColor: 'gray'} : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed ?
          {...base, fontWeight: 'bold', color: 'white', paddingRight: 6} :
          base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? {...base, display: 'none'} : base;
    },
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <RightMessages />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">Messages
                    <a className="fw-700 ms-auto cursor-pointer font-xssss" onClick={toggleCreateOpen}>Create new
                      group</a>
                    <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${createOpen ? 'show' : ''}`} style={{width: 500}}>
                      <div className="form-group">
                        <label className="ms-auto mont-font fw-600 font-xsss mb-2">
                          Select Group Members
                        </label>
                        <CreatableSelect
                          isMulti
                          value={selected}
                          isClearable={selected.some((v) => !v.isFixed)}
                          styles={styles}
                          onChange={onChange}
                          onCreateOption={onCreateOption}
                          className="react-select react-fix"
                          classNamePrefix="react-select"
                          formatCreateLabel={(e) => `Add '@${e}'`}
                          noOptionsMessage={(e) => 'Type to add someone else'}
                          isLoading={creating}
                          isDisabled={creating}
                          options={options}
                        />
                      </div>
                      <div className="mt-2">
                        <Button className="w-100 d-block" disabled={selected.length < 2 || creatingGroup} loading={creatingGroup} onClick={createGroup}>Create Group</Button>
                      </div>
                    </div>
                    {/* <form action="#" className="pt-0 pb-0">
                      <div className="search-form-2 ms-2">
                        <i className="ti-search font-xss"></i>
                        <input type="text" className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                      </div>
                    </form>*/}
                  </h2>
                </div>

                <div className="row ps-2 pe-1">
                  {entries.map((group, index) => (
                    <div key={index} className="col-12 pe-2 ps-2 position-relative">
                      {group.unread > 0 && <span className="right-chat-circle-count top-0 left-0 z-index-1 bg-warning position-absolute" style={{right: 'auto'}}>{group.unread}</span>}
                      <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2  cursor-pointer" onClick={() => history.push(`/messages/${group.id}`)}>
                        <div className="card-body on-hover backdrop-filter d-flex w-100 ps-4 pe-4 pb-0 pt-0 text-left position-relative">
                          {group.imageUid != null ? <figure className="avatar float-left my-auto me-2 d-inline-block">
                            <img src={`${process.env.REACT_APP_API_URL}/image/${group.imageUid}/thumb.webp`} alt="avater" className="w50 rounded-circle"/>
                          </figure> :
                              <figure className="avatar float-left my-auto me-2 cursor-pointer d-inline-block" onClick={() => history.push(`/messages/${group.id}`)}>
                                <span className="btn-round-lg bg-primary-gradiant my-auto w50 mw-100 text-white font-xssss fw-700">{getInitials(group.name)}</span>
                              </figure>}
                          <div className="d-block w-100">
                            <div className="d-flex"><h4 className="fw-700 font-xsss mt-3 mb-1">{group.name}</h4> <span className="ms-auto font-xssss mt-2 mb-1">{getRelativeTime(new Date(group.lastMessageDate))}</span></div>
                            <div className="d-flex">
                              <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">{group.lastMessage && (group.lastMessage.systemMessage ? group.lastMessage.message : `${group.lastMessage.profile.name}: ${group.lastMessage.message}`)}</p>
                              <BsChevronRight className="ms-auto slide-in-rightleft"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  ))}

                  {hasMore && <Load />}


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}
