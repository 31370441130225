import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';
import {adsApi} from './ads/adsApi';
import {eventsApi} from './events/eventsApi';
import {communityApi} from './community/communityApi';
import {appApi} from './appApi';


// ** Reducers

export const store = configureStore({
  reducer: {
    [adsApi.reducerPath]: adsApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [communityApi.reducerPath]: communityApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
      .concat(adsApi.middleware)
      .concat(eventsApi.middleware)
      .concat(communityApi.middleware)
      .concat(appApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
