import {useEffect, useRef, useState} from 'react';
import Load from '../Load';
import axios from 'axios';
import {FeedEntry, FeedResponse} from '../../api-types';
import PrimaryFeedEntry from './PrimaryFeedEntry';

type PrimaryFeedProps = {
  type?: 'TRENDING' | 'LATEST' | 'FOLLOWING'
  tag?: string
}

const url = {
  'TRENDING': '/feeds/primary',
  'LATEST': '/feeds/latest',
  'FOLLOWING': '/feeds/following',
};

export default function PrimaryFeed({type = 'LATEST', tag} :PrimaryFeedProps) {
  const entriesRef = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [cursorId, setCursorId] = useState<string | undefined>(undefined);
  const [entries, setEntries] = useState<FeedEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState<boolean>(true);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setHasMore(true);
    setEntries([]);
    setLoading(false);
    setFirst(true);
    setCursorId(undefined);
    setSkip(0);
  }, [type, tag]);

  const more = () => {
    if (loading || !hasMore) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}${tag == undefined ? url[type]: `/feeds/latest/tag/${tag}`}?skip=${skip}&take=10${cursorId != undefined ? `&cursorId=${cursorId}` : ''}`, {withCredentials: true})
        .then((res) => {
          const data: FeedResponse = res.data;
          setLoading((c) => {
            if (c) {
              setEntries((a) => [...a, ...data.entries]);
              setHasMore(data.has_more);
              setCursorId(data.cursor_id);
              setSkip(skip + 10);
            }
            return false;
          });
        });
  };

  const onScroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
      more();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  if (first) {
    setSkip(0);
    more();
    setFirst(false);
  }

  return (
    <div ref={entriesRef}>
      {entries.flatMap((entry, index) => (<PrimaryFeedEntry key={index} entry={entry}/>))}
      {hasMore && <Load/>}
    </div>
  );
}
