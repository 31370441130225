import {Link, useHistory} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import {useUser} from '../helpers/auth-context';
import PageAlert from '../components/PageAlert';
import Select from 'react-select';
import {useRef, useState} from 'react';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import Button from '../components/Button';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import {useAlert} from '../helpers/alert-provider';

const API_URL = process.env.REACT_APP_API_URL;

function EditProfile() {
  const {user, reloadUser} = useUser();

  const profileTypes = [
    {value: 'PERSONAL', label: 'Personal'},
    {value: 'ORGANISATION', label: 'Organization'},
  ];
  const profileVisibilities = [
    {value: 'PUBLIC', label: 'Public'},
    {value: 'PRIVATE', label: 'Private (followers only)'},
  ];

  const profile = user?.activeProfile?.profile;

  const [type, setType] = useState(profile?.type);
  const [visibility, setVisibility] = useState(profile?.visibility);
  const [realname, setRealname] = useState(profile?.name);
  const [biography, setBiography] = useState(profile?.bio);

  const [profilePicture, setProfilePicture] = useState(profile?.pictureUid);
  const [uploadingProfilePic, setUploadingProfilePic] = useState(false);

  const [bannerPicture, setBannerPicture] = useState(profile?.bannerUid);
  const [bannerFile, setBannerFile] = useState(undefined);
  const [uploadingBannerPicture, setUploadingBannerPicture] = useState(false);


  // Field errors
  const [profilePictureError, setProfilePictureError] = useState(undefined);
  const [bannerPictureError, setBannerPictureError] = useState(undefined);
  const [nameError, setNameError] = useState(undefined);
  const [bioError, setBioError] = useState(undefined);
  const [submitError, setSubmitError] = useState(undefined);
  const [tagError, setTagError] = useState(undefined);


  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [addingBusiness, setAddingBusiness] = useState(false);
  const [updatingTag, setUpdatingTag] = useState(false);

  const tagField = useRef<HTMLInputElement>();

  const alert = useAlert();

  const history = useHistory();

  if (!user) {
    return (
      <RequireUser> </RequireUser>
    );
  }

  if (!profile) {
    return <PageAlert link="/profile/select" title="Oops! You don't have a profile active!"
      subtitle="You can only view this page with a profile active!" linkText="Choose profile"/>;
  }


  function uploadProfilePicture(imageBlob, filename) {
    const formData = new FormData();
    formData.set('image', imageBlob, filename);
    formData.set('type', 'profile_picture');
    setUploadingProfilePic(true);
    axios.post(`${API_URL}/image/upload`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
        .then((res) => {
          if (res.data.files.length > 0) {
            setProfilePicture(res.data.id);
          }
          setUploadingProfilePic(false);
        })
        .catch((err) => {
          setProfilePictureError(err.message);
          setUploadingProfilePic(false);
        });
  }

  function uploadBannerPicture(imageBlob) {
    const formData = new FormData();
    formData.set('image', imageBlob, imageBlob.name);
    formData.set('type', 'banner_picture');
    setUploadingBannerPicture(true);
    axios.post(`${API_URL}/image/upload`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
        .then((res) => {
          if (res.data.files.length > 0) {
            setBannerPicture(res.data.id);
            setBannerFile(imageBlob.name);
          }
          setUploadingBannerPicture(false);
        })
        .catch((err) => {
          setBannerPictureError(err.message);
          setUploadingBannerPicture(false);
        });
  }


  function submit(e) {
    e.preventDefault();
    if (submitting) return;

    setNameError(undefined);
    setBioError(undefined);
    setBannerPictureError(undefined);
    setProfilePictureError(undefined);

    let r = false;
    if (!/^.{3,32}$/.test(realname)) {
      setNameError('Realname must be between 3 and 32 characters!');
      r = true;
    }

    if (biography.length > 300) {
      setNameError('Biography must be cannot be more than 300 characters');
      r = true;
    }
    if (r) return;

    const formData = {
      type: type,
      visibility: visibility,
      bio: biography,
      name: realname,
      picture: profilePicture,
      banner: bannerPicture,
    };

    setSubmitting(true);
    axios.post(`${API_URL}/profile/update`, formData, { // TODO: some loading and error indication
      withCredentials: true,
    })
        .then((res) => {
          reloadUser();
          setSubmitting(false);
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 2000);
        })
        .catch((err) => {
          setSubmitting(false);
          setSubmitError(err.message);
        });
  }

  function addBusiness() {
    if (addingBusiness) return;
    setAddingBusiness(true);
    axios.post(`${API_URL}/business/create`, {

    }, {withCredentials: true}).then((res) => {
      setAddingBusiness(false);
      reloadUser();
    });
  }

  function updateTag() {
    if (updatingTag) return;
    setUpdatingTag(true);
    alert.setAlert({
      title: 'Are you sure?',
      content: 'Are you sure you want to change your tag? You can only do this once every 7 days.',
      onConfirm: handleUpdateTag,
      onClose: () => {
        setUpdatingTag(false);
      },
    });
  }

  function handleUpdateTag() {
    if (updatingTag) return;
    setTagError(undefined);
    axios.post(`${API_URL}/profile/tag`, {
      tag: tagField.current.value,
    }, {withCredentials: true, validateStatus: () => true}).then((res) => {
      if (res.status === 429) {
        // relative date time format
        const rtf = new Intl.RelativeTimeFormat('en', {style: 'long'});

        const remainingMs = res.data.remainingMs;
        const targetDate = new Date(Date.now() + remainingMs);
        const now = new Date();

        const diffInSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        let relativeTime;
        if (diffInDays > 0) {
          relativeTime = `${rtf.format(diffInDays, 'day')} at ${targetDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
        } else if (diffInHours > 0) {
          relativeTime = `${rtf.format(diffInHours, 'hour')} and ${diffInMinutes % 60} minutes`;
        } else if (diffInMinutes > 0) {
          relativeTime = `${rtf.format(diffInMinutes, 'minute')}`;
        } else {
          relativeTime = 'just now';
        }

        setTagError(`You can change your tag again ${relativeTime}`);
      } else if (res.status === 409) {
        setTagError(`Tag @${tagField.current.value} is already in use`);
      } else if (res.status === 400) {
        setTagError('This tag is not valid. Please use only letters, numbers, and underscores and must be between 3 and 16 characters');
      } else if (res.status !== 200) {
        setTagError('An unknown error occurred');
      } else {
        reloadUser().then(() => {
          alert.setAlert({
            title: 'Success!',
            content: 'Your tag has been updated!',
          });
        });
      }
      setUpdatingTag(false);
    });
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to={'/p/'+profile.tag} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className=" font-xs text-white fw-600 ms-4 mb-0 mt-2">Account Details</h4>
                  <Link to={'/profile/edit/members'} className="link-red-header fw-600 ms-auto mb-0 mt-1">Manage Members</Link>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 text-center form-group">
                      <input type="file" name="profile-picture" id="profile-picture" accept="image/*"
                        className="input-file"
                        onChange={(e) => {
                          uploadProfilePicture(e.target.files[0], e.target.files[0].name);
                        }}/>
                      <label htmlFor="profile-picture" className="cursor-pointer">
                        <figure
                          className={`avatar ms-auto me-auto mb-0 mt-2 w100 ${uploadingProfilePic && 'img-loading'}`}
                          style={{position: 'relative', height: '100px', width: '100px'}}>
                          <div className="loader"/>
                          <img
                            src={profilePicture ? `${API_URL}/image/${profilePicture}/thumb.webp` : `https://mc-heads.net/avatar/1284718947582947891/100`}
                            alt="avatar"
                            className="shadow-sm rounded-3 w-100"/>
                        </figure>
                      </label>
                      <h2 className="fw-700 font-sm text-grey-900 mt-3">{profile.name}</h2>
                      <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">@{profile.tag}</h4>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Tag</label>
                        <div className="d-flex flex-row">
                          <input type="text" className={`${tagError ? 'is-invalid' : ''} form-control`} ref={tagField}
                            defaultValue={profile.tag}
                          />
                          <Button className="w-100 ms-1 flex-1" style={{flex: 1}} disabled={updatingTag}
                            loading={updatingTag}
                            onClick={updateTag}
                          >Change</Button>
                        </div>
                        <div className="text-red font-xsss fw-500 ">
                          {tagError}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Real name</label>
                        <input type="text" className={`form-control ${nameError && 'is-invalid'}`} value={realname}
                          onChange={(e) => setRealname(e.target.value)}/>
                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                          {nameError}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Profile
                                                    Type</label> <br/>
                        <div className="d-flex flex-row">
                          {profile.businessProfile !== null ?
                            <input type="text" className="form-control" disabled
                              value='Business'/> :
                          <>
                            <Select
                              options={profileTypes}
                              defaultValue={profileTypes.find((a) => a.value === profile.type)}
                              onChange={(newValue) => {
                                setType(newValue.value);
                              }}
                              className="react-select me-1  flex-1"
                              classNamePrefix="react-select"
                            />
                            <Button className="w-100 ms-1 flex-1" style={{flex: 1}} disabled={profile.type !== 'ORGANISATION' || profile.businessProfile !== null}
                              loading={addingBusiness}
                              onClick={addBusiness}
                            >Add Business</Button>
                          </>}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label
                          className="mont-font fw-600 font-xsss mb-2">Visibility</label>
                        <br/>
                        <Select
                          options={profileVisibilities}
                          defaultValue={profileVisibilities.find((a) => a.value === profile.visibility)}
                          onChange={(newValue) => {
                            setVisibility(newValue.value);
                          }}
                          className="react-select react-fix-1-8"
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label
                      className="mont-font fw-600 font-xsss mb-2 text-dark">Biography</label>
                    <textarea className={`form-control mb-0 p-3 h100 bg-greylight lh-16 ${bioError && 'is-invalid'}`}
                      rows={5} maxLength={300} placeholder="Write your message..." value={biography}
                      onChange={(e) => setBiography(e.target.value)}></textarea>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                      {bioError}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div
                          className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label
                              className="mont-font fw-600 font-xsss mb-2 text-dark">Banner
                                                            Picture</label>
                            <input type="file" name="banner-picture" id="banner-picture"
                              className="input-file" onChange={(e) => {
                                uploadBannerPicture(e.target.files[0]);
                              }}/>
                            <label htmlFor="banner-picture"
                              className={`rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed ${bannerPictureError && 'is-invalid '}`} >
                              <i className={`${bannerFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                              <span className="js-fileName">{bannerFile ? bannerFile : `Click to upload a new banner!`}</span>
                            </label>
                            <div className="invalid-feedback text-red font-xsss fw-500 ">
                              {bannerPictureError}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <a href="/profile/update"
                        className={`bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block ${submitting && 'disabled'} ${submitError && 'is-invalid'}`} onClick={submit}>{completed ? 'Saved!' : 'Save'}</a>
                      <div className="invalid-feedback text-red font-xsss fw-500 ">
                        {submitError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}

export default EditProfile;
