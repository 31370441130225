import React, {Component, Fragment, useState} from 'react';
import {Link} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import {FaCrown, FaPencilAlt, FaUserAlt, FaWrench} from 'react-icons/fa';
import {FiX} from 'react-icons/fi';
import axios from 'axios';
import Alert from '../components/Alert';
import RightMessages from '../components/header/RightMessages';
import {useAlert} from '../helpers/alert-provider';
import {useProfileLimitQuery} from '../store/appApi';

const API_URL = process.env.REACT_APP_API_URL;

export default function ProfileList() {
  const {user, reloadUser} = useUser();
  const {setAlert} = useAlert();
  const {data: maxProfiles} = useProfileLimitQuery();

  if (!user) {
    return <RequireUser/>;
  }

  const activeProfileId = user.activeProfile?.profileId;
  const profiles = user.profiles;

  function deleteProfile(membership) {
    setAlert({
      title: membership.role === 'OWNER' ? `You are about to delete @${membership.profile.tag}` : `You are about to leave @${membership.profile.tag}`,
      content: membership.role === 'OWNER' ? `Are you sure you want to delete the this profile? This action is irreversible!` : `Are you sure you want to leave this profile? You will have to ask the of the profile to add you again to undo this.`,
      onConfirm: () => confirmDeleteProfile(membership),
    });
  }

  function getRoleIcon(role) {
    switch (role.toLowerCase()) {
      case 'owner':
        return (<FaCrown/>);
      case 'admin':
        return (<FaWrench/>);
      case 'author':
        return (<FaPencilAlt/>);
      default:
        return (<FaUserAlt/>);
    }
  }

  function switchProfile(id) {
    axios.post(`${API_URL}/profile/active`, {
      profileId: id,
    }, {withCredentials: true})
        .then((res) => {
          reloadUser();
        });
  }

  function confirmDeleteProfile(membership) {
    axios.delete(`${API_URL}/profile/${membership.profile.tag}`, {withCredentials: true})
        .then((res) => {
          reloadUser();
        });
  }

  return (

    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">

                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row  mb-lg-5 mb-4 ">
                        <div className="col align-items-center d-flex">
                          <h4 className=" font-xxl fw-700 mont-font font-md-xs d-inline-block mb-0 ">Profiles</h4> <span
                            className="bg-warning text-white font-xsssss rounded-max ms-2 ls-3 fw-600 py-1 px-2 mt-0 d-inline-block">{user.profiles.filter((p) => p.role === 'OWNER').length}/{maxProfiles?.maxProfiles}</span>
                        </div>
                        <div className="col-auto align-items-center d-flex">
                          <Link to="/profile/create" className="fw-700 ms-1">Create new
                                                        profile</Link>
                        </div>
                      </div>
                      <div
                        className="profile-wrapper p-3 w-100 position-relative scroll-bar">
                        <ul className="profile-list">
                          {profiles.map((profileUser, index) => (

                            <li key={index}>

                              <div
                                className={`profile-entry rounded-3`}>
                                <div className="profile-user">
                                  {profileUser.profileId === activeProfileId ? <span
                                    className="btn-round-xss ms-0 bg-primary me-2"></span> :
                                                                        <span
                                                                          className="btn-round-xss ms-0 bg-transparent me-2"></span>}
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/image/${profileUser.profile.pictureUid}/thumb.webp`}
                                    alt="user"
                                    className="w35 me-2"/>
                                  <div style={{lineHeight: '12px'}}>
                                    <h6 className="font-xssss text-grey-900 mb-0 mt-0 fw-700">{profileUser.profile.name}</h6>
                                    <span
                                      className="font-xssss text-grey-900">@{profileUser.profile.tag}</span>
                                  </div>
                                </div>
                                <div
                                  className="email-subject text-grey-900 text-dark fw-600 font-xssss">
                                  <span
                                    className="font-sm me-2">{getRoleIcon(profileUser.role)}</span>
                                  {`${profileUser.role[0].toUpperCase()}${profileUser.role.slice(1).toLowerCase()}`}
                                </div>
                                <div className="ms-auto">
                                  <a href="/profile/list" className="ms-auto" onClick={(e) => {
                                    e.preventDefault();
                                    switchProfile(profileUser.profileId);
                                  }}><i
                                      className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a>
                                  <a href="/profile/list" onClick={(e) => {
                                    e.preventDefault();
                                    deleteProfile(profileUser);
                                  }}
                                  className="ms-auto font-sm text-danger ms-auto mt-3"><FiX/></a>
                                </div>
                              </div>
                            </li>

                          ))}


                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </Fragment>);
}
