import {Fragment, useRef, useState} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {useUser} from '../helpers/auth-context';
import useSWR from 'swr';

export default function RegisterDiscord() {
  const usernameRef = useRef<HTMLInputElement>();
  const termsRef = useRef<HTMLInputElement>();
  const {user} = useUser();

  const [error, setError] = useState(undefined);
  const [termsError, setTermsError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/auth/discord/pending`, fetcher);

  if (!data) {
    return <></>;
  }

  if (data.data === null) {
    return <Redirect to="/login"/>;
  }

  if (user) {
    return <Redirect to="/settings/linked/discord"/>;
  }

  function submit() {
    if (!termsRef.current.checked) return setTermsError('You must agree to the terms of service to register.');
    else setTermsError(undefined);

    if (loading) return;
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/auth/discord/register`, {username: usernameRef.current.value}, {withCredentials: true, validateStatus: () => true})
        .then((res) => {
          const data = res.data;
          if (data.type === 'ERROR') {
            setError(data.message);
          } else if (data.type === 'OK') {
            setDone(true);
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
          }
          setLoading(false);
        });
  }

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <a href="/"><img src="/assets/images/REAL_LOGO.svg" alt="logo" style={{height: '50px'}}/><span className="ms-2 d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">onrain </span> </a>
            <button className="nav-menu me-0 ms-auto"></button>

            <a href="/login"
              className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
            <a href="/register"
              className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
          </div>
        </div>


        <div className="row">
          <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{backgroundImage: `url("/assets/images/login-side-image.png")`}}></div>
          <div
            className="col-xl-7 align-items-center d-flex bg-white rounded-3 centered-card">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br/>your
                                            account</h2>
                <form>

                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-link text-grey-500 pe-0"></i>
                    <input
                      readOnly
                      type="text"
                      name="discord"
                      value={data.data.username}
                      className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 `}
                      placeholder="Your Username"/>
                  </div>

                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                    <input type="text"
                      name="username"
                      ref={usernameRef}
                      className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${error != undefined && 'is-invalid'}`}
                      placeholder="Your Username"/>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                      {error}
                    </div>
                  </div>
                  <div className="form-check text-left mb-3">
                    <input type="checkbox"
                      className={`form-check-input mt-2 ${termsError && 'is-invalid'}`}
                      name="terms"
                      ref={termsRef}
                    />
                    <label className="form-check-label font-xsss checkbox-text pt-1">Accept
                                                    Term
                                                    and Conditions</label>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    You must accept the terms and conditions to create an account.
                    </div>

                  </div>
                </form>

                <div className="col-sm-12 p-0 text-left">
                  <div className="form-group mb-1"><a href='#' onClick={submit}
                    className={`${loading && 'disabled'} form-control text-center style2-input text-white fw-600  bg-dark border-0 p-0 `}>{!done? 'Create New Account' : 'Account Created! Login via the login page.'}</a>
                  </div>
                  <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have
                                                account? <a href="/login" className="fw-700">Login</a> and link your discord via the settings</h6>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );
}
