import React, {Fragment, useState} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {BsFillCloudHazeFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import FollowRequestButtons from '../components/FollowRequestButtons';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../components/Loading';
import {useParams} from 'react-router-dom';
import NotAllowed from './NoPermission';
import RightMessages from '../components/header/RightMessages';
import {
  useCommunityMemberRoleMutation,
  useCommunityMembersQuery,
  useCommunityQuery,
} from '../store/community/communityApi';
import Notfound from './Notfound';
import Load from '../components/Load';
import {Community, CommunityMember, CommunityRole} from '@prisma/client';
import Select from 'react-select';
import {AlertProps, useAlert} from '../helpers/alert-provider';


export default function CommunityMembers() {
  const {tag} = useParams<{tag: string}>();
  const {data: members, refetch} = useCommunityMembersQuery(tag);
  const {user} = useUser();
  const {data: community, isError} = useCommunityQuery(tag);
  const [updateRole, {isLoading}] = useCommunityMemberRoleMutation();
  const [search, setSearch] = useState('');
  const {setAlert} = useAlert();
  if (!members) return <Loading/>;
  if (isError) return <Notfound />;
  if (!community) return (<Load></Load>);

  const membership:CommunityMember & {community: Community} | undefined = user?.activeProfile?.profile?.communities?.find((m:CommunityMember) => m.communityId === community.id);
  if (community.type === 'PRIVATE' && membership === undefined) return <NotAllowed/>;

  const isAdmin = membership && ['ADMIN', 'OWNER'].includes(membership.role);
  const communityRoles = [
    {label: 'Member', value: 'MEMBER'},
    {label: 'Admin', value: 'ADMIN'},
    {label: 'Moderator', value: 'MODERATOR'},
    {label: 'Owner', value: 'OWNER'},
  ];

  function update(profileId: string, role: CommunityRole) {
    if (role === CommunityRole.OWNER) {
      const alert: AlertProps = {
        title: 'Transfer Ownership',
        content: 'Are you sure you want to transfer ownership of this community?',
        onConfirm: () => {
          updateRole({tag, profileId, role}).unwrap()
              .then(() => refetch());
        },
      };
      setAlert(alert);
    } else {
      updateRole({tag, profileId, role}).unwrap()
          .then(() => refetch());
    }
  }

  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title={`${community.tag} Members`} search={isAdmin ? setSearch : undefined}/>
                <div className="row ps-2 pe-2">
                  {members.filter(({profile}) => profile.tag.includes(search)).map(({profile, ...member}, index) => (
                    <div key={index} className="col-md-4 col-sm-6 col-12 pe-2 ps-2">
                      <div
                        className="card d-block border-0 shadow-xss rounded-3  overflow-visible mb-3">
                        <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                          <Link to={`/p/${profile.tag}`}>
                            <figure
                              className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65">
                              <img
                                height={65}
                                width={65}
                                src={`${process.env.REACT_APP_API_URL}/image/${profile.pictureUid}/thumb.webp`}
                                alt="avatar"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"/>
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">{profile.name} </h4>
                            <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">@{profile.tag}</p>
                          </Link>
                          {isAdmin && <Select
                            options={communityRoles}
                            defaultValue={communityRoles.find((a) => a.value === member.role)}
                            onChange={(newValue) => {
                              if (newValue) update(member.profileId, newValue.value as CommunityRole);
                            }}
                            className="react-select m-2 flex-1 z-index-1"
                            classNamePrefix="react-select"
                            isLoading={isLoading}
                            styles={{menu: (provided) => ({...provided, zIndex: 9999})}}
                          />}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter/>

    </Fragment>
  );
}
