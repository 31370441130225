import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const appApi = createApi({
  reducerPath: 'app/appApi',
  baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_API_URL + '/', credentials: 'include'}),
  endpoints: (builder) => ({
    version: builder.query<{version: string, build: string}, void>({
      query: () => ({
        url: 'version',
      }),
    }),
    profileLimit: builder.query<{maxProfiles: number}, void>({
      query: () => ({
        url: 'profilelimit',
      }),
    }),
  }),
});

export const {
  useVersionQuery,
  useProfileLimitQuery,
} = appApi;
